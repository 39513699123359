import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HearthIcon from '@material-ui/icons/Favorite';

import useApi, { SOFT_SALE_CLOSE } from '~hooks/useApi';
import Dialog from '~components/Dialog';
// import AbLogoImage from '~images/popups/ab-logo.png';
import AbBlackLogo from '~images/popups/black-logo.png';
// import Link from '~components/Link';
import { lineFix } from '~utils/popup-helpers';
import { useTestId } from '~utils/set-testid';
import { SUBSCRIBE_PATH } from '~utils/subscribe-helpers';
import ButtonClosePopup from './components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '50px',
    fontWeight: 700,
    lineHeight: '52px',
    marginBottom: '20px',
    letterSpacing: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '27px',
      lineHeight: '32px',
      fontWeight: 700,
      textAlign: 'center'
    }
  },
  subHeading: {
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '47px',
    marginBottom: '20px',
    letterSpacing: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: 700,
      textAlign: 'center'
    }
  },
  buttonStyle: {
    backgroundColor: '#122119',
    color: '#fff',
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '28px',
    padding: '16px 46px',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#000'
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 20px'
    }
  },
  iconStyle: {
    fontSize: '25px',
    color: theme.palette.secondary.main,
    marginRight: '12px'
  },
  logoImage: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  imageMedia: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  buttonPosition: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: 12
    }
  },
  closeText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'block',
    marginBottom: '15px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '180px'
    }
  },
  paragraphStyle: {
    textAlign: 'left',
    marginBottom: '20px',
    fontWeight: 400,
    lineHeight: 1.57,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: 1.4,
      marginBottom: '10px'
    }
  },
  boldParagraphStyle: {
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: 1.57,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: 1.4
    }
  },
  overlineText: {
    textAlign: 'left',
    marginBottom: '20px',
    fontWeight: 500,
    lineHeight: 1.57,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: 1.4,
      fontSize: '14px',
      marginBottom: '10px'
    }
  },
  mobileImagePosition: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  },
  guestButtonStyle: {
    backgroundColor: '#122119',
    color: '#fff',
    textTransform: 'none',
    fontSize: '16px',
    padding: '16px 26px',
    marginBottom: theme.spacing(),
    '&:hover': {
      backgroundColor: '#000'
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 20px'
    }
  },
  guestSecondButtonStyle: {
    backgroundColor: '#fff',
    textTransform: 'none',
    fontSize: '16px',
    padding: '16px 26px',
    marginBottom: theme.spacing(),
    '&:hover': {
      backgroundColor: '#EFFEF7'
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 20px'
    }
  },
  loginLine: {
    marginTop: '15px',
    fontWeight: '500'
  },
  fixedImage: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    marginBottom: '-16px',
    zIndex: 1
  }
}));

const GenericPopup = ({
  popupData,
  showTopCloseBtn,
  setPopupId,
  id = undefined,
  isPopupOpen,
  handlePopupClose,
  which
}) => {
  const classes = useStyles();
  const {
    image,
    backdrop,
    isMobileImageSmall,
    title,
    subHeader,
    subtitle,
    boldSubtitle,
    buttonTitle,
    secondButtonTitle,
    // hasLoginLine,
    ctaLink,
    isFixedImage
  } = popupData;
  const [isOpen, setIsOpen] = useState(true);
  const [, makeRequest] = useApi();
  const testid = useTestId(`generic-popup-${id}`);

  const handleClose = async () => {
    if (which) {
      const params = SOFT_SALE_CLOSE({ which });
      await makeRequest(params);
    }

    if (handlePopupClose) {
      handlePopupClose();
      return;
    }
    if (setPopupId) {
      setPopupId(0);
    }

    setIsOpen(false);
  };

  return (
    <Dialog
      isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
      id={id}
      backdrop={backdrop}
      background="transparent"
      maxWidth="lg"
      {...testid}
    >
      <Box
        component="img"
        src={AbBlackLogo}
        alt="Aleph Beta logo"
        className={classes.logoImage}
        pb={2}
      />
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={4}>
        <Grid item xs={12} md={6} style={{ zIndex: 5 }}>
          <Typography className={classes.heading}>{lineFix(title)}</Typography>
          {subHeader && (
            <Typography className={classes.subHeading}>{lineFix(subHeader)}</Typography>
          )}
          <Typography variant="subtitle1" className={classes.boldParagraphStyle}>
            {boldSubtitle}
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphStyle}>
            {subtitle}
          </Typography>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={secondButtonTitle ? 6 : 12}>
              <Box className={classes.buttonPosition}>
                <Button
                  component={GatsbyLink}
                  variant="contained"
                  className={secondButtonTitle ? classes.guestButtonStyle : classes.buttonStyle}
                  to={secondButtonTitle ? '/checkout/register' : SUBSCRIBE_PATH}
                  size="large"
                  fullWidth
                >
                  {/* {!secondButtonTitle && <HearthIcon className={classes.iconStyle} />} */}
                  {buttonTitle}
                </Button>
              </Box>
            </Grid>
            {secondButtonTitle && (
              <Grid item xs={12} sm={secondButtonTitle ? 6 : 12}>
                <Box className={classes.buttonPosition}>
                  <Button
                    component={GatsbyLink}
                    variant="contained"
                    className={classes.guestSecondButtonStyle}
                    to="/subscribe"
                    size="large"
                    fullWidth
                  >
                    <HearthIcon className={classes.iconStyle} />
                    {secondButtonTitle}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          {ctaLink && (
            <Box
              component={Typography}
              variant="subtitle1"
              align="center"
              className={classes.closeText}
              onClick={handleClose}
            >
              {ctaLink}
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={isMobileImageSmall ? 6 : 10}
          sm={6}
          md={isMobileImageSmall ? 5 : 6}
          className={isFixedImage ? classes.fixedImage : classes.mobileImagePosition}
        >
          <img src={image.src} alt={image.alt} className={classes.imageMedia} />
        </Grid>
      </Grid>

      {showTopCloseBtn && <ButtonClosePopup handleClose={handleClose} />}
    </Dialog>
  );
};

GenericPopup.propTypes = {
  popupData: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    }).isRequired,
    backdrop: PropTypes.string.isRequired,
    isMobileImageSmall: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    boldSubtitle: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    secondButtonTitle: PropTypes.string,
    hasLoginLine: PropTypes.bool,
    isFixedImage: PropTypes.bool,
    ctaLink: PropTypes.string
  }).isRequired,
  showTopCloseBtn: PropTypes.bool,
  setPopupId: PropTypes.func,
  id: PropTypes.string,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  which: PropTypes.number
};

GenericPopup.defaultProps = {
  showTopCloseBtn: false,
  setPopupId: null,
  id: undefined,
  isPopupOpen: null,
  handlePopupClose: null,
  which: null
};

export default GenericPopup;
