import htmlParser from 'html-react-parser';
import PhoneImage from '~images/popups/phone-image.png';
import LaptopImage from '~images/popups/laptop-image.png';
import PartnersImage from '~images/popups/partners.png';
import { getNavigationPageLink } from '~utils/common-site-helper';
import mosheHittingRockImage from '~images/popups/moshe-hitting-rock.png';
import { SUBSCRIBE_PATH } from './subscribe-helpers';

export const lineFix = text => {
  const t = text.replace(/\s+(\S+\s*$)/, '&nbsp;$1');
  return htmlParser(t);
};

export const POPUPS_DATA = {
  GUEST_SOFT_SALE_FIRST: {
    id: 'banner_guest_soft_sale_1'
  },
  GUEST_SOFT_SALE_SECOND: {
    id: 'banner_guest_soft_sale_2'
  },

  FIRST_SOFT_SALE: {
    id: 'banner_guest_soft_sale'
  },

  GUEST_BLOCKER: {
    id: 'banner_guest_video_blocked',
    backdrop: '#88FAC3',
    title: `We're glad you're enjoying Aleph Beta`,
    subtitle: 'Please create a free account to continue',
    buttonTitle: 'Create Free Account',
    secondButtonTitle: 'Support Us Today',
    hasLoginLine: true,
    image: {
      src: PhoneImage,
      alt: 'Mobile phone'
    },
    isMobileImageSmall: true
  },
  REGISTERED_PRESALE_ONE: {
    id: 'banner_user_soft_sale_12',
    backdrop: '#88FAC3',
    title: `We're glad you're enjoying Aleph Beta`,
    subtitle: `You've watched 10 minutes of your free 18 minutes this month!`,
    buttonTitle: 'Support Us Today',
    ctaLink: 'Keep Watching',
    image: {
      src: PhoneImage,
      alt: 'Mobile phone'
    },
    isMobileImageSmall: true
  },
  REGISTERED_BLOCKER: {
    id: 'banner_user_video_blocked',
    backdrop: '#FFE99B',
    title: `We're glad you're enjoying Aleph Beta`,
    subtitle: `You've watched 30 free minutes this month. But don't worry! You can upgrade now and get unlimited access!`,
    buttonTitle: 'Get Unlimited Access',
    ctaLink: 'I don’t want to support at this time',
    image: {
      src: LaptopImage,
      alt: 'Laptop'
    }
  },
  REGISTERED_PRESALE_TWO: {
    id: 'banner_user_soft_sale_2'
  },
  PREMIUM_CONTENT_BLOCKER: {
    id: 'premium_content_blocker',
    image: {
      src: LaptopImage,
      alt: 'Laptop'
    },
    backdrop: '#FFE99B',
    title: `You've clicked on one of our Premium courses. Great choice!`,
    subtitle: `Premium courses are our most in-depth content, designed especially for the truly dedicated learner who wants to explore Rabbi Fohrman's methodology on the highest level. Premium members also provide Aleph Beta with invaluable support through a small monthly subscription fee.  Partner with us in spreading Torah across the globe.`,
    buttonProps: {
      title: `Explore Premium`,
      href: SUBSCRIBE_PATH
    },
    ctaLinkProps: {
      title: 'Already have a Premium account? Log in here.',
      href: getNavigationPageLink('/login')
    }
  },
  FAILED_CHARGES_BLOCKER: {
    id: 'failed_charges_blocker',
    image: {
      src: LaptopImage,
      alt: 'Laptop'
    },
    backdrop: '#FFE99B',
    title: `Billing Notice`,
    subtitle: `Oh, no, it looks like there's a temporary hold on your account. Please make sure your subscription is up-to-date to continue enjoying unlimited access.`,
    buttonProps: {
      title: `Update billing information`,
      href: '/account/billing'
    },
    ctaLinkProps: {
      title: 'Need help? Email info@alephbeta.org.',
      href: 'mailto:info@alephbeta.org'
    }
  },
  THANK_YOU_FOR_NOT_CANCELLING: {
    id: 'thank_you_for_not_cancelling_popup',
    image: {
      src: LaptopImage,
      alt: 'Laptop'
    },
    title: `Thank you for changing your mind!`,
    subtitle: `Premium courses are our most in-depth content, designed especially for the truly dedicated learner who wants to explore Rabbi Fohrman's methodology on the highest level. Premium members also provide Aleph Beta with invaluable support through a small monthly subscription fee. Especially now, when our basic membership is free, signing up for Premium is the best way to show your support for what we do and partner with us in spreading Torah across the globe.`,
    buttonTitle: `Maybe you would even consider upgrading your plan?`
  },
  PRODUCER_CIRCLE_CONTENT_BLOCKER: {
    id: 'producer_circle_content_blocker',
    image: {
      src: PartnersImage,
      alt: 'Partner with us!'
    },
    backdrop: '#f2a271',
    title: `You've clicked on some of our Patrons Circle content. Great choice!`,
    subtitle: `If you are passionate about what we’re doing here at Aleph Beta, then we invite you to join our inner circle. Patrons Circle members are Aleph Beta’s backbone and biggest supporters, allowing us to develop new learning projects that mine the mysteries of the Torah, and to share what we learn with even more new users every day.`,
    buttonProps: {
      title: `Explore Patrons Circle`,
      href: '/patrons-circle'
    },
    ctaLinkProps: {
      title: 'Already have a Patrons Circle account? Log in here.',
      href: getNavigationPageLink('/login')
    }
  },
  UPGRADE_TO_PREMIUM_FOR_FEATURE: {
    id: 'upgrade_to_premium_for_feature',
    image: {
      src: LaptopImage,
      alt: 'Laptop'
    },
    backdrop: '#FFE99B',
    title: `Join our community`,
    subtitle: `Please subscribe to enjoy this feature. It would mean the world to have your support.`,
    buttonProps: {
      title: `Explore Premium`,
      href: SUBSCRIBE_PATH
    },
    ctaLinkProps: {
      title: 'Already have a Premium account? Log in here.',
      href: getNavigationPageLink('/login')
    }
  },
  PDF_TOO_LARGE_FOR_DOWNLOAD: {
    id: 'pdf_too_large_for_download',
    image: {
      src: mosheHittingRockImage,
      alt: 'Rock'
    },
    backdrop: '#ffffff',
    title: `Oops!`,
    subtitle: `We can't seem to find the page you're looking for. Please reach out to info@alephbeta.org for further support.`,
    buttonProps: {
      title: `Go back home`,
      href: '/'
    }
  },
  TRANSCRIPTS_LIMIT: {
    id: 'transcripts_limit',
    image: {
      src: LaptopImage,
      alt: 'Laptop'
    },
    backdrop: '#FFE99B',
    title: `Join our community`,
    subtitle: `You have reached your limit of opening 2 transcripts per month.`,
    buttonProps: {
      title: `Explore Premium`,
      href: SUBSCRIBE_PATH
    },
    ctaLinkProps: {
      title: 'Already have a Premium account? Log in here.',
      href: getNavigationPageLink('/login')
    }
  }
};

export const POPUP_USER_FLOW_DATA = [
  {
    title: 'Guest User Flow',
    popups: [
      // {
      //   id: 11,
      //   title: 'Soft-Sale at 5 minutes'
      // },
      // {
      //   id: 12,
      //   title: 'Soft-Sale at 7.5 minutes'
      // },
      {
        id: 10,
        title: 'Blocking Popup at 10 minutes'
      }
    ]
  },
  {
    title: 'Registered User Flow',
    popups: [
      {
        id: 21,
        title: 'Call-To-Action at 10 minutes'
      },
      {
        id: 20,
        title: 'Block Popup at 30 minutes'
      }
    ]
  },
  {
    title: 'Miscellaneous Popups',
    popups: [
      {
        id: 70,
        title: 'Failed Charges Blocker'
      },
      {
        id: 71,
        title: 'Thank you for not cancelling'
      },
      {
        id: 25,
        title: 'Premium Content Blocker'
      },
      {
        id: 80,
        title: 'Patrons Circle Content blocker'
      }
    ]
  }
];
