import { isBlackFridayOfferEnded } from '~components/black-friday/blackFridayUtils';
import { FEATURE_FLAGS, isFeatureEnabled } from './feature-helpers';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);
const isBlackFridayOfferEnabled = IS_BLACK_FRIDAY_OFFER_ENABLED && !isBlackFridayOfferEnded();

export const SUBSCRIBE_PATH = isBlackFridayOfferEnabled ? '/subscribe-now' : '/subscribe';

/* eslint-disable import/prefer-default-export */
export const formatPrice = price => {
  if (typeof price !== 'number') {
    return '0.0';
  }
  const formattedPrice = Number(price);
  const p = formattedPrice.toFixed(2);
  const r = p.toString().split('.');
  if (r[1] === '00') {
    return r[0];
  }
  return p;
};
