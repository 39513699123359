import isBot from 'isbot';
import { isIOS } from 'react-device-detect';
import { REDIRECT_DATA } from '~layout/MobileAppBlocker/MobileAppBlockerPopup';
import { getNavigationPageLink } from '~utils/common-site-helper';
import { BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH } from '~components/black-friday/constants';
import { isBlackFridayOfferEnded } from '~components/black-friday/blackFridayUtils';
import ExperimentTracking from './experiment-tracking';
import { FEATURE_FLAGS, isFeatureEnabled } from './feature-helpers';
import { SUBSCRIBE_PATH } from './subscribe-helpers';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);
const isBlackFridayOfferEnabled = IS_BLACK_FRIDAY_OFFER_ENABLED && !isBlackFridayOfferEnded();

export const AB_EXPERIMENTS = {
  PUSH_TO_APP: {
    NAME: 'Push to App strategy',
    VARIANTS: {
      CONTROL: 'control',
      NO_PUSH: 'no-push',
      FORCE_PUSH: 'force-push'
    },
    IS_CROSS_DOMAIN: true
  },
  VIDEO_PLAYLIST: {
    NAME: 'Marketing Playlist Experiment',
    VARIANTS: {
      CONTROL: 'control',
      NO_BANNER: 'no-banner',
      PROMINENT_PLAY_BUTTON: 'prominent-play-button'
    }
  }
};

export const setPushToAppExperiment = isSubscriber => {
  const { userAgent } = typeof window !== 'undefined' ? window.navigator : {};
  // only iOS users
  // when black friday offer is enabled, we don't want to show the push to app experiment
  const isTargetAudience =
    !isSubscriber && isIOS && !isBot(userAgent) && !isBlackFridayOfferEnabled;

  if (isTargetAudience) {
    ExperimentTracking.trackAndGetVariant(
      AB_EXPERIMENTS.PUSH_TO_APP.NAME,
      Object.values(AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS),
      AB_EXPERIMENTS.PUSH_TO_APP.IS_CROSS_DOMAIN,
      [AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.CONTROL] // Control variant should not be assigned
    );
  }
};

export const getPushToAppExperimentCtaData = () => {
  // target audience refer to the audience that we want to show the push to app experiment
  // when black friday offer is enabled, we don't want to show the push to app experiment
  const isTargetAudience = isIOS && !isBlackFridayOfferEnabled;

  // default is set to NO PUSH (for non ios users)
  const variant = isTargetAudience
    ? ExperimentTracking.getVariant(
        AB_EXPERIMENTS.PUSH_TO_APP.NAME,
        AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.NO_PUSH
      )
    : AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.NO_PUSH;

  switch (variant) {
    case AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.FORCE_PUSH: {
      const pushUrl = isIOS ? REDIRECT_DATA.iOSLink : REDIRECT_DATA.androidLink;
      return { text: 'Start your free trial', url: pushUrl };
    }
    default: {
      // when black friday offer is enabled, we don't want to show the free trial
      const text = isBlackFridayOfferEnabled ? 'Sign Up' : 'Start your free trial';
      const url = isBlackFridayOfferEnabled
        ? getNavigationPageLink(BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH, true)
        : getNavigationPageLink(SUBSCRIBE_PATH, true);
      return { text, url };
    }
  }
};
