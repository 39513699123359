import Cookies from 'js-cookie';

const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

/**
 * This map is the single source of truth for the FE feature flags
 */
export const FEATURE_FLAGS = {
  MOBILE_APP_REDIRECT_POPUP: 'FF-FE-MOBILE_APP_REDIRECT_POPUP-20230608',
  SKIP_ONBOARDING: 'FF-FE-SKIP_ONBOARDING-20230609',
  BANNER_AT_BOTTOM: 'FF-FE-BANNER_AT_BOTTOM-20230612',
  TISHA_BAV: 'FF-FE-TISHA_BAV-20230702',
  THREE_WEEKS: 'FF-FE-THREE_WEEKS-20230703',
  PREMIUM_OPTIONS: 'FF-FE-PREMIUM_OPTIONS-20230905',
  BLACK_FRIDAY_OFFER: 'FF-FE-BLACK_FRIDAY_OFFER-20231115',
  /** Switch the frontend to the mode of being a "marketing site" */
  MARKETING_SITE: 'FF-Marketing-Site',
  WATCH_HISTORY_HIGHLIGHT: 'FF-Watch-History-Highlight',
  BUZZSPROUT_PODCAST_PLAYER: 'FF-FE-BUZZSPROUT_PODCAST_PLAYER-20240104',
  FREE_TRIAL: 'FF-FE-FREE_TRIAL',
  VWO_INTEGRATION: 'FF-FE-VWO_INTEGRATION',
  PERKS_PAGE: 'FF-FE-Perks_Page',
  BROWSE_LIBRARY: 'FF-FE-BROWSE_LIBRARY'
};

const isEnabledFeatureFlagValid = enabledFeatureFlag => {
  if (isProduction) {
    return Boolean(enabledFeatureFlag);
  }
  // for non production environments, we allow any enabled feature flag to be disabled using cookies
  return Boolean(enabledFeatureFlag) && Cookies.get(enabledFeatureFlag) !== '0';
};
/**
 * GATSBY_ENABLED_FEATURE_FLAGS is a comma separated strings of enabled features
 */
const ENABLED_FEATURE_FLAGS = (process.env.GATSBY_ENABLED_FEATURE_FLAGS || '')
  .split(',')
  .map(value => String(value).trim())
  .filter(isEnabledFeatureFlagValid)
  .reduce((enabledMap, featureFlag) => ({ ...enabledMap, [featureFlag]: true }), {});

export const isFeatureEnabled = featureFlag =>
  !!ENABLED_FEATURE_FLAGS[featureFlag] || Cookies.get(featureFlag) === '1';
