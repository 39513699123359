import { useUserContext } from '~context/UserContext/UserSessionContext';
import { isBlackFridayOfferEnded } from './blackFridayUtils';

/**
 * Hook to check whether the user/guest is a black friday target audience
 * Black friday target audience = user is not a subscriber && offer period is not over
 * @returns {boolean} black friday target audience user or not
 */
const useTargetAudience = () => {
  const { session } = useUserContext();
  const { isSubscriber, isUserStateLoading } = session;

  // check if offer period is over
  if (isBlackFridayOfferEnded()) {
    return false;
  }

  // subscribed users are not black friday target audience
  return !isUserStateLoading && !isSubscriber;
};

export default useTargetAudience;
